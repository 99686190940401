<template>
  <div class="row justify-content-center">

    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="mb-0">{{ $t("DADOSPRO.NOME") }}</h3>
            <button v-if="lista_campos.id > 1" @click="editarProduto == false
              ? (editarProduto = true)
              : (editarProduto = false)
              " :class="editarProduto == false ? 'btn-danger' : 'btn-warning'" class="btn">
              {{ editarProduto == false ? "Bloquear" : "Editar" }}
            </button>
          </div>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group sm row">
              <div class="col-md-6">
                <label class="col-md-12">{{ $t("NOMBRE.NOME") }}</label>
                <input :disabled="editarProduto" required type="text" class="form-control" v-model="form.nome"
                  placeholder="Escribe el nombre" />
              </div>
              <div class="col-md-6">
                <label class="col-md-12">{{ $t("TIPOPRODUTO.NOME") }}:*</label>
                <select :disabled="editarProduto" required type="text" class="form-control"
                  v-model="form.tipo_produto_id" placeholder="Escribe el tipo de Producto">
                  <option v-for="lista in lista_tipos_produto" :key="lista.id" :value="lista.id">
                    {{ lista.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label class="col-md-12">{{ $t("DESCRICAO.NOME") }}:*</label>
                <input :disabled="editarProduto" required type="text" class="form-control" v-model="form.descricao"
                  placeholder="Escribe la descripción" />
              </div>
              <div v-if="lista_campos.id" class="col-md-3">
                <label class="col-md-12">Fracción Local</label>
                <input :disabled="true" required type="text" class="form-control" v-model="form.filial_fracao"
                  placeholder="" />
              </div>
              <div v-if="lista_campos.id" class="col-md-3">
                <label class="col-md-12">Cantidad Local</label>
                <input :disabled="true" required type="text" class="form-control" v-model="form.filial_quantidade"
                  placeholder="" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label class="col-md-12">Laboratorio:*</label>
                <!-- <select
                    :disabled="editarProduto"
                    required
                    type="text"
                    class="form-control"
                    v-model="form.laboratorio_id"
                  >
                    <option value="">Selecione:</option>
                    <option
                      v-for="lista in lista_laboratorios"
                      :key="lista.id"
                      :value="lista.id"
                    >
                      {{ lista.nome }}
                    </option>
                  </select> -->
                <treeselect :disabled="editarProduto" v-model="form.laboratorio_id" :multiple="false"
                  :options="lista_laboratorios" />
              </div>
              <div v-if="lista_campos.id" class="col-md-3">
                <label class="col-md-12">Fracción total {{ empresa }}</label>
                <input :disabled="true" required type="text" class="form-control"
                  :value="total_empresa.fracao_empresa" />
              </div>
              <div v-if="lista_campos.id" class="col-md-3">
                <label class="col-md-12">Cantidad total {{ empresa }}:*</label>
                <input :disabled="true" required type="text" class="form-control"
                  :value="total_empresa.estoque_empresa" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-2">
                <label class="col-md-12">Codigo de Barras:*</label>
                <input required :disabled="editarProduto" type="text" class="form-control" v-model="form.codigo_barra"
                  placeholder="Escriba el codigo de Barras" />
              </div>
              <!-- <div class="col-md-2">
                <label class="col-md-12">
                  <b-form-checkbox id="checkbox-1"  name="checkbox-1" value="accepted"
                    unchecked-value="not_accepted">
                   Cantidad mínima
                  </b-form-checkbox>
                </label>
                <input type="text" class="form-control" v-model="form.estoque_quantidade"
                  placeholder="Escriba la cantidad" />
              </div> -->
              <div class="col-md-2">
                <label class="col-md-12">Fracción:*</label>
                <input required :disabled="editarProduto" type="text" class="form-control" v-model="form.fracao"
                  placeholder="Escriba la Fración" />
              </div>
              <div v-if="lista_campos.id" class="col-md-2">
                <label class="col-md-12">Cantidad:*</label>
                <input :disabled="true" type="text" class="form-control" v-model="form.quantidade"
                  placeholder="Escriba la cantidad" />
              </div>

              <div v-if="lista_campos.id" class="col-md-2">
                <label class="col-md-12">Fración General</label>
                <input :disabled="true" required type="text" class="form-control" v-model="form.estoque_fracao"
                  placeholder="Digite quantidade fracao estoque" />
              </div>
              <div v-if="lista_campos.id" class="col-md-2">
                <label class="col-md-12">Cantidad General:*</label>
                <input :disabled="true" required type="text" class="form-control" v-model="form.estoque_quantidade"
                  placeholder="Digite quantidade fracao estoque" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button @click.prevent="confirm(!lista_campos.id ? 'cria' : 'edita')" class="btn btn-primary"
                  :disabled="verif">
                  Guardar
                  <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- lista de estoques -->
    <div v-if="lista_campos.id" class="col-md-12 pt-4">
      <div class="card full-height bg-ligth">
        <div class="card-header d-flex justify-content-between">
          <h3 class="mb-0">Datos del Stock</h3>

          <div>
            <b-button v-b-modal.estoque @click="limpa_campos()">
              <span class="fas fa-plus"></span></b-button>

            <b-modal ref="modal_estoque" size="lg" centered id="estoque" title="Stock">
              <div class="form">
                <div class="form-group row">
                  <div class="col-md-6">
                    <label class="col-md-12">Fecha de Recepción:*</label>
                    <input required type="date" class="form-control" v-model="form_estoque.data_recebimento" />
                  </div>

                  <div class="col-md-6">
                    <label class="col-md-12">{{ $t('ESTOQUES.VALIDADE') }}:*</label>
                    <input required type="date" class="form-control" v-model="form_estoque.validade" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="col-md-12">Lote:*</label>
                    <input required type="text" class="form-control" v-model="form_estoque.lote"
                      placeholder="N de lote" />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-12">Cantidad:*</label>
                    <input required type="number" class="form-control" v-model="form_estoque.estoque_quantidade"
                      placeholder="quantidade" />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-12">Entrada por:*</label>
                    <select required type="text" class="form-control" v-model="form.tipo_entrada_id"
                      placeholder="seleccione">
                      <option value="1">Compra</option>
                      <option value="2">Donación</option>
                      <option value="3">Bonificación</option>

                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="col-md-12">Valor total (Gs):
                      <span v-b-tooltip.hover
                        title="Acá queda el valor total pago en el estoque cerrado. EN GUARANÍES!"><svg
                          xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                          class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                          <path
                            d="M16 8A8 8 
                        0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                        </svg></span></label>
                    <input required type="text" class="form-control" v-model="valor_total_estoque" @input="parseInput"
                      placeholder="G$0" />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-12">Valor Unitário (Gs):
                      <span v-b-tooltip.hover
                        title="Este valor es calculado automáticamente despues que ya haya valor total y cantidad total llenados">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                          class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                          <path
                            d="M16 8A8 8 
                        0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                        </svg></span></label>
                    <input placeholder="valor unitario (Gs)" type="text" class="form-control"
                      v-model="form_estoque.valor_unitario" />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-12">Valor por Fracción (Gs):
                      <span v-b-tooltip.hover
                        title="Es el valor de cada fracion del producto, os sea el valor que quedará vinvulado a cada salida por paciente. Es calculado automáticamente despues que ya haya valor total y cantidad total llenados">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                          class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                          <path
                            d="M16 8A8 8 
                        0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                        </svg></span></label>
                    <input placeholder="valor fracionario (Gs)" type="text" class="form-control"
                      v-model="form_estoque.valor_fracionario" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-4">
                    <label class="col-md-12">Sala:*</label>
                    <b-form-select :options="lista_sala" v-model="form_estoque.sala_id" required></b-form-select>
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-12">Fila:*</label>
                    <input required type="text" class="form-control" v-model="form_estoque.fila" />
                  </div>
                  <div class="col-md-4">
                    <label class="col-md-12">Columna:*</label>
                    <input required type="text" class="form-control" v-model="form_estoque.coluna" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="col-md-12">{{ $t('DESCRICAO.NOME') }}:*</label>
                    <input required type="text" class="form-control" v-model="form_estoque.descricao" />
                  </div>
                </div>
              </div>

              <template #modal-footer>
                <div class="w-100">
                  <div class="text-center">
                    <button v-if="form_estoque.estoque_id == null" @click.prevent="confirm('agrega en stock')"
                      class="btn btn-primary" :disabled="verif">
                      Guardar
                      <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                    </button>
                    <button v-if="form_estoque.estoque_id != null" @click.prevent="confirm('actualiza un stock de')"
                      class="btn btn-primary" :disabled="verif">
                      Actualizar
                      <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                    </button>
                  </div>
                  <!-- <b-button
                    variant="danger"
                    
                    class=" btn-light float-right mt-3"
                    @click="$bvModal.hide('estoque')"
                    >Fechar</b-button
                  > -->
                </div>
              </template>
            </b-modal>

          </div>
        </div>

        <div class="card-body row justify-content-center">
          <div class="col-md-12">
            <b-table :fields="[
              'lote',
              'validade',
              'data_recebimento',
              'estoque_quantidade',
              'estoque_fracao',
              'valor_compra',
              'valor_atual',
              'valor_unit',
              'valor_fracionario',
              'acciones',
            ]" responsive :items="lista_produtos_estoque" :per-page="perPage" :current-page="currentPage"
              id="sala-table"
              class="table table-responsive table-head-custom table-vertical-center table-head-bg table-borderless"
              show-empty empty-text="Nenhum registro encontrado!">
              <template #head(acoes)><span></span></template>
              <template #cell(valor_tracionario)>{{ fomatarGuarani(item.valor_fracionario.toString()) }}</template>
              <template #head(validade)="{ item }">Validad </template>
              <template #head(valor_atual)="{ item }">Valor Actual </template>
              <template #head(data_recebimento)="{ item }">Fecha Recib. </template>
              <template #head(estoque_quantidade)="{ item }">Cantidad </template>
              <template #head(valor_fracionario)="{ item }">Valor Fracción</template>
              <template #head(estoque_fracao)="{ item }"> Fraccion</template>
              <template #cell(validade)="item"> {{ formatarData(item) }} </template>
              <template #cell(data_recebimento)="item">{{ formatarDataReb(item) }}</template>
              <template #cell(acciones)="{ item }">
                <div class="text-right w-100">

                  <b-button @click="transferirEstoque(item)" class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Transferir stocks">
                    <i class="fas fa-exchange-alt text-success"></i>
                    <!-- <i class="fa fa-right-left"></i> -->
                  </b-button>

                  <b-button @click="editarEstoque(item)" class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover
                    title="Editar Stock">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>

                  <button @click="confirm('exclui la cantidad total de este stock', item)"
                    class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Excluir estoque">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </button>

                  <b-button @click="abrir_historico(item)" class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover
                    title="ver historico">
                    <i class="fa fa-info"></i>
                  </b-button>

                  <b-button @click="regularizarEstoque(item)" class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Regularizar estoque">
                    <i class="flaticon-edit-1"></i>
                  </b-button>

                </div>
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="lista_produtos_estoque.length" :per-page="perPage"
              aria-controls="local-table">
            </b-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- Substancia -->
    <div v-if="lista_campos.id" class="col-md-6 pt-4">
      <div class="card full-height bg-ligth">
        <div class="card-header d-flex justify-content-between">
          <h3 class="mb-0">Sustancias</h3>

          <div>
            <b-button v-b-modal.substancias>
              <span class="fas fa-plus"></span>
            </b-button>

            <b-modal centered id="substancias" ref="modal_substancia" title="Sustancias">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group label="Substanica:*">
                    <b-form-select :options="lista_substancias" v-model="form_substancias.substancia_id"
                      required></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <template #modal-footer>
                <div class="text-center w-100">
                  <button @click.prevent="confirm('vincula una sustancia a')" class="btn btn-primary" :disabled="verif">
                    Vincular
                    <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                  </button>
                  <!-- <b-button
                    variant="danger"
                    class="float-right mt-3"
                    @click="$bvModal.hide('substancias')"
                    >Fechar</b-button -->
                  <!-- > -->
                </div>
              </template>
            </b-modal>
          </div>
        </div>
        <div class="card-body">
          <b-table :fields="['nome', 'acoes']" :items="lista_substancias_produto" :per-page="perPage"
            :current-page="currentPage" id="substancia_produto-table"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless" show-empty
            empty-text="Nenhum registro encontrado!">
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button @click="confirm('desvincula la substancia de', item)" class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover title="Desvincular registro">
                  <i class="fas fa-times text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <!-- CLASSES -->
    <div v-if="lista_campos.id" class="col-md-6 pt-4">
      <div class="card full-height bg-ligth">
        <div class="card-header d-flex justify-content-between">
          <h3 class="mb-0">Clases</h3>

          <b-button v-b-modal.Classes>
            <span class="fas fa-plus"></span>
          </b-button>

          <b-modal centered ref="modal_classes" id="Classes" title="Classes">
            <div class="my-4 form">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group label="Classes:*">
                    <b-form-select :options="lista_tipos_classe" v-model="form_classe.tipo_classe_id"
                      required></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>
            <template #modal-footer>
              <div class="text-center w-100">
                <button @click.prevent="confirm('vincula una classe a')" class="btn btn-primary" :disabled="verif">
                  Vincular
                  <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                </button>
              </div>

              <!-- <div class="">
                  <b-button
                    variant="danger"
                    class="btn-light"
                    @click="$bvModal.hide('Classes')"
                    >Fechar</b-button
                  >
                </div> -->
            </template>
          </b-modal>
          <modalTransferencia></modalTransferencia>
        </div>
        <div class="card-body">
          <b-table :fields="['nome', 'acoes']" :items="listar_tipos_classe_produto" :per-page="perPage"
            :current-page="currentPage" id="tipo_classe-table"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless" show-empty
            empty-text="Nenhum registro encontrado!">
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button @click="confirm('desvincula la classe de', item)" class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover title="Desvincular Classe">
                  <i class="fas fa-times text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <!-- Modal Historico -->
    <b-modal size="lg" ref="modal_historico" centered id="historico" title="Historico de modificaciones">
      <div>
        <b-nav tabs fill>
          <b-nav-item :active="historico" @click="selecionarTipo('historico')">Historico</b-nav-item>
          <b-nav-item :active="saida" disabled @click="selecionarTipo('saida')">Saidas</b-nav-item>
        </b-nav>
        <b-nav-form>
          <b-table v-if="historico"
            class="table table-responsive table-head-custom table-vertical-center table-head-bg table-borderless"
            :fields="[
              'data_modificacao',
              'tipo',
              'dados_anteriores',
              'modificacoes',
              'nome_usuario',
              'mas_datos',
            ]" :items="dados_historico">
            <template #cell(tipo)="{ item }">
              <span class="label label-lg label-inline label-light-primary" v-if="item.tipo == 'CREATED'">{{ item.tipo
                }}</span>
              <span class="label label-lg label-inline label-light" v-if="item.tipo == 'UPDATE'">{{ item.tipo }}</span>
              <span class="label label-lg label-inline label-light-primary"
                v-if="item.tipo == 'RECEB. TRANSFERENCIA'">{{
                  item.tipo }}</span>
              <span class="label label-lg label-inline label-light-danger"
                v-if="item.tipo == 'TRANSFERENCIA CANCELADA'">{{ item.tipo }}</span>
              <span class="label label-lg label-inline label-light-info" v-if="item.tipo == 'TRANSFERENCIA'">{{
                item.tipo
              }}</span>
              <span class="label label-lg label-inline label-light-danger"
                v-if="item.tipo == 'TRANSFERENCIA DEVOLVIDA'">{{ item.tipo }}</span>
              <span class="label label-lg label-inline label-light-warning" v-if="item.tipo == 'VENCIMENTO'">{{
                item.tipo
              }}</span>
              <span class="label label-lg label-inline label-light-warning" v-if="item.tipo == 'DANIFICADO/DAÑADO'">{{
                item.tipo }}</span>
              <span class="label label-lg label-inline label-light-warning" v-if="item.tipo == 'RETROACTIVO'">{{
                item.tipo
              }}</span>
              <span class="label label-lg label-inline label-light-danger" v-if="item.tipo == 'DELETE SAIDA'">{{
                item.tipo
              }}</span>
              <span class="label label-lg label-inline label-light-success" v-if="item.tipo == 'SALIDA PACIENTE'">{{
                item.tipo }}</span>
              <span href="#" class="label label-lg label-inline label-light-info" v-if="item.tipo == 'DONACION'">{{
                item.tipo
              }}</span>
              <span class="label label-lg label-inline label-light-info" v-if="item.tipo == 'SALIDA UNICA'">{{ item.tipo
                }}</span>
              <span class="label label-lg label-inline label-light-warning" v-if="item.tipo == 'REGULARIZACION'">{{
                item.tipo
              }}</span>
            </template>
            <template #cell(dados_anteriores)="{ item }">
              <span class="font-size-lg" v-if="item.dados_anteriores != null">
                Cantidad: {{ item.dados_anteriores.estoque_quantidade }}
              </span>

              <p class="font-size-lg" v-if="item.dados_anteriores != null">
                Fracion: {{ item.dados_anteriores.estoque_fracao }}
              </p>
            </template>
            <template #cell(modificacoes)="{ item }">
              <span class="font-size-lg">
                Cantidad: {{ item.modificacoes.estoque_quantidade }} 
              </span>
              <p class="font-size-lg">
                Fracion: {{ item.modificacoes.estoque_fracao }}
              </p>
              <p class="font-size-lg" v-if="item.tipo == 'REGULARIZACION'">
                Motivo: {{ item.modificacoes.motivo }}
              </p>
              <!-- <p class="font-size-lg" v-if="item.tipo == 'TRANSFERENCIA'">
                Filial:  {{ item.modificacoes.filial_id }}
              </p> -->
            </template>
            <template #cell(mas_datos)="{ item }">
              <button class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="En breve mas informaciones">
                <i class="fa fa-info"></i>
              </button>
            </template>
          </b-table>
          <b-table v-if="saida" class="table table-responsive table-head-custom table-head-bg table-borderless" :fields="[
            'data',
            'quantidade',
            'paciente',
            'ocorrencia',
            'nome_usuario',
          ]">
          </b-table>
        </b-nav-form>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <div class="text-center">
            <button @click="fechar" class="btn btn-primary" :disabled="verif">
              Cerrar
              <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
            </button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- modal transferencia -->
    <b-modal ref="modal_transferencia" centered id="transferencia">
      <template #modal-title>
        Transferencia de Stocks
        <i class="far fa-question-circle text-gray" v-b-tooltip.hover
          title="Esta funcionalidad envía una determinada cantidad de medicamentos o productos a otro establecimiento. Al hacer clic en guardar, la cantidad se restará del stock actual y deberá ser recibida y confirmada por la sucursal de destino para agregarla al nuevo stock.">
        </i>

      </template>
      <div class="form">
        <div class="form-group row">
          <div class="col-md-6">
            <label class="col-md-12">Fecha transferencia:*</label>
            <input required type="date" class="form-control" v-model="form_transferencia.data_transferencia" />
          </div>
          <div class="col-md-6">
            <label class="col-md-12">{{ $t('ESTOQUES.VALIDADE') }}:</label>
            <input required disabled type="date" class="form-control" v-model="form_transferencia.data_validade" />
          </div>

        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-md-12">Cantidad:*</label>
            <input required type="text" class="form-control" v-model="quantidade" />
          </div>

          <div class="col-md-3">
            <label class="col-md-12">Fracción:</label>
            <input required disabled type="text" class="form-control" v-model="form.fracao" />
          </div>

          <div class="col-md-5">
            <label class="col-md-12">Cantidad en Fracción:</label>
            <input required disabled type="text" class="form-control" v-model="form_transferencia.estoque_fracao" />
          </div>



        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label class="col-md-12">Filial de destino:*</label>
            <b-form-select :options="lista_filials" v-model="form_transferencia.filial_id" required></b-form-select>
          </div>
          <div class="col-md-3">
            <label class="col-md-12">Fila:*</label>
            <input required type="text" class="form-control" v-model="form_transferencia.fila" />
          </div>
          <div class="col-md-3">
            <label class="col-md-12">Columna:*</label>
            <input required type="text" class="form-control" v-model="form_transferencia.coluna" />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12">Sala:*</label>
            <b-form-select :options="lista_sala" v-model="form_transferencia.sala_id" required></b-form-select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12">Descripción:*</label>
            <input required type="text" class="form-control" v-model="form_transferencia.descricao" />
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <div class="text-center">
            <button @click="confirm('Transfere parte del stock')" type="button" class="btn btn-primary"
              :disabled="verif">
              Enviar
              <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
            </button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- modal regularização -->
    <b-modal ref="modal_regularizacao" centered id="modal_regularizacao" title="Regularizar Stock">
      <div class="form">
        <div class="form-group row">

          <div class="col-md-5">
            <label class="col-md-12">Cantidad en Fracción:</label>
            <input required type="number" class="form-control" v-model="form_regularizacao.estoque_fracao" />
          </div>
          <div class="col-md-2">
            <label class="col-md-12">Fracción:*</label>
            <input required disabled type="text" class="form-control" v-model="form.fracao" />
          </div>
          <div class="col-md-5">
            <label class="col-md-12">Cantidad:*</label>
            <input required disabled type="text" class="form-control" v-model="form_regularizacao.estoque_quantidade" />
          </div>

        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12">Tipo de Regularización:</label>

            <select required type="text" class="form-control" v-model="form_regularizacao.tipo_id"
              placeholder="seleccione">
              <option value="1">Add cantidad</option>
              <option value="2">Retirar cantidad</option>

            </select>

          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12">Motivo:*</label>
            <textarea :rows="calculateRows(form_regularizacao.motivo)" required type="text" class="form-control"
              v-model="form_regularizacao.motivo" placeholder="Escribe..."></textarea>
          </div>

        </div>


      </div>

      <template #modal-footer>
        <div class="w-100">
          <div class="text-center">
            <button @click="confirm('Regulalariza el stock')" type="button" class="btn btn-primary" :disabled="verif">
              Salvar
              <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
            </button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import modalTransferencia from "../produto/modalTransferencia.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { getEmpresaId } from "../../../../core/services/filial.service";
// import Button from '../../../../../../ucp-clinica-back/vendor/laravel/jetstream/stubs/inertia/resources/js/Jetstream/Button.vue';

export default {
  components: {
    modalTransferencia,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Productos" }]);
  },
  mixins: [fireAlert],
  data() {
    return {
      dados_historico: [],
      saida: false,
      historico: true,
      form_regularizacao: {
        tipo_id: null,
        motivo: "",
        estoque_quantidade: 0,
        estoque_fracao: 0,
        fracao: 1,
        estoque_id: null,
      },
      form: {
        nome: "",
        estoque_fracao: "",
        descricao: "",
        tipo_produto_id: "",
        laboratorio_id: "",
        status: 1,
        codigo_barra: "",
        quantidade: "",
        fracao: "",
        estoque_produto: "",
        filial_quantidade: "",
        fracao_quantidade: "",
      },
      form_substancias: {
        substancia_id: null,
        produto_id: null,
      },
      form_classe: {
        tipo_classe_id: null,
        produto_id: null,
      },
      form_estoque: {
        valor: "",
        lote: "",
        descricao: "",
        validade: "",
        data_recebimento: "",
        produto_id: "",
        status: 1,
        estoque_quantidade: "",
        estoque_fracao: "",
        fila: "",
        coluna: "",
        sala_id: "",
        estoque_id: null,
        valor_unitario: "",
        valor_fracionario: "",
        tipo_entrada_id: null,

      },
      form_transferencia: {
        estoque_quantidade: null,
        estoque_fracao: null,
        data_transferencia: "",
        filial_id: "",
        descricao: "",
        sala_id: "",
        estoque_id: "",
        data_validade: "",
        lote: "",
        produto_id: "",
        fila: "",
        coluna: "",
        valor_unitario: "",
      },
      quantidade: null,
      valor_total_estoque: null,
      valor_unitario: null, //para mostrar
      verif: false,
      currentPage: 1,
      perPage: 11,
      editarProduto: false,
      estoque_id: null,
      teste: "",
      rota: "",
      value: 0,

      empresa: "",
    };
  },
  created() {
    this.listar_requerimentos();
    if (this.$route.params) {
      this.rota = "createProduto/" + this.$route.params;
      console.log(this.rota);
    }
  },
  watch: {
    quantidade() {
      this.form_transferencia.estoque_quantidade = this.quantidade;
      this.form_transferencia.estoque_fracao =
        parseInt(this.form_transferencia.estoque_quantidade) *
        parseInt(this.form.fracao);
    },
    'form_regularizacao.estoque_fracao'(value) {
      this.form_regularizacao.estoque_quantidade = Math.trunc(this.form_regularizacao.estoque_fracao / this.form.fracao)
      console.log(this.form_estoque)
      if (this.form_regularizacao.estoque_fracao < this.form_estoque.estoque_fracao) {
        this.form_regularizacao.tipo_id = 2
      } else if (this.form_regularizacao.estoque_fracao > this.form_estoque.estoque_fracao) {
        this.form_regularizacao.tipo_id = 1
      }

    },
  },
  computed: {
    lista_tipos_classe() {
      let option = [];
      option.push({ value: null, text: "Selecione" });
      this.$store.state.tipoClasse.lista_tipos_classe.forEach((element) => {
        option.push({ value: element.id, text: element.nome });
      });
      return option;
    },
    lista_filials() {
      let option = [];
      option.push({ value: null, text: "Selecione" });
      this.$store.state.configEmpresa.lista_filials.forEach((element) => {
        option.push({ value: element.id, text: element.nome });
      });
      return option;
    },
    lista_sala() {
      let option = [];
      option.push({ value: null, text: "Selecione" });
      this.$store.state.configEmpresa.lista_sala.forEach((element) => {
        option.push({ value: element.id, text: element.nome });
      });
      return option;
    },
    lista_substancias_produto() {
      return this.$store.state.produto.lista_substancias_produto;
    },
    lista_produtos_estoque() {
      return this.$store.state.produto.lista_produtos_estoque;
    },
    listar_tipos_classe_produto() {
      return this.$store.state.produto.listar_tipos_classe_produto;
    },
    lista_tipos_produto() {
      return this.$store.state.produto.lista_tipos_produto;
    },
    lista_setor() {
      return this.$store.state.configEmpresa.lista_setor;
    },
    lista_substancias() {
      let option = [];
      option.push({ value: null, text: "Selecione" });
      this.$store.state.substancia.lista_substancias.forEach((element) => {
        option.push({ value: element.id, text: element.nome });
      });
      return option;
    },
    lista_laboratorios() {
      return this.$store.state.laboratorio.lista_laboratorios;
    },
    lista_campos() {
      // return JSON.parse(localStorage.getItem("produto"));
      return this.$store.state.produto.produto;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    total_empresa() {
      return this.$store.state.produto.total_empresa;
    }
  },
  methods: {
    calculateRows(text) {
      const lineCount = text.split('\n').length;
      const minRows = 1;
      return Math.max(minRows, lineCount);
    },

    parseInput() {
      this.valor_total_estoque = this.fomatarGuarani(this.valor_total_estoque);
      console.log(this.valor_total_estoque)
      // const input = this.valor_total_estoque.replace(/[^\d]/g, "");
      const input = this.valor_total_estoque.replace(/[^\d.]/g, "");
      this.form_estoque.valor = this.valor_total_estoque.replace(/[^\d]/g, "");


      let valor_unitario = input / this.form_estoque.estoque_quantidade
      valor_unitario = valor_unitario.toFixed(3)
      console.log('valor_unitario ', valor_unitario)
      this.form_estoque.valor_unitario = valor_unitario

      let valor_fracionario = (valor_unitario / this.form.fracao).toFixed(3)
      console.log('fracao  ', valor_fracionario)
      this.form_estoque.valor_fracionario = valor_fracionario
    },

    async confirm(tipo, id = null) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta acción  ` + tipo + ` un producto del sistema.`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") { this.create(); }
            if (tipo == "edita") { this.update(); }
            if (tipo == "vincula una sustancia a") { this.vinculoSubstancia(); }
            if (tipo == "vincula una classe a") { this.vinculoClasse(); }
            if (tipo == "agrega en stock") { this.setProdutoEstoque(); }
            if (tipo == "exclui la cantidad total de este stock") { this.deleteEstoque(id); }
            if (tipo == "Transfere parte del stock") { this.salvarTransferencia(); }
            if (tipo == "actualiza un stock de") { this.salvarUpdateEstoque(); }
            if (tipo == "desvincula la substancia de") {
              this.desvincular_subs(id);
            }
            if (tipo == "desvincula la classe de") {
              this.desvincular_classe(id);
            }
            if (tipo == "desvincula la classe de") {
              this.desvincular_classe(id);
            }
            if ('Regulalariza el stock') { this.salvarRegularizacao() }
          }
        },
      });
    },

    fomatarGuarani(valor) {
      const input = valor.replace(/[^\d]/g, ""); // remove tudo que não for número
      const value = Number.parseInt(input, 10);
      const formatted = new Intl.NumberFormat("es-PY", {
        style: "currency",
        currency: "PYG",
        currencyDisplay: "code",
      }).format(value);
      return formatted;
    },

    async create() {
      this.verif = true;
      await this.$store.dispatch("produto/create_produto", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "produto",
      });
    },

    async update() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      this.verif = true;
      this.$store.dispatch("produto/update_produto", this.form);
      this.verif = false;

      await this.$store.dispatch(
        "produto/listar_produto_estoque_id",
        this.form.id
      );

      this.$store.dispatch("configEmpresa/MudarPreloader", false);

      this.fireAlert(this.mensagem_alert);
    },

    async vinculoSubstancia() {
      const { produto_id } = this.$route.params;

      if (!produto_id) {
        this.fireAlert({
          title: "Nenhum produro foi selecionado!",
          tipo: "error",
          routeName: "aluno",
          timer: 5000,
        });

        this.$store.dispatch("MudarPreloader", false);
        return;
      }

      this.form_substancias.produto_id = produto_id;
      this.verif = true;
      await this.$store.dispatch(
        "produto/vinculo_substancia",
        this.form_substancias
      );

      this.verif = false;
      this.fireAlert(this.mensagem_alert);
      form_substancias.substancia_id = "";
      form_substancias.produto_id = "";

      this.$refs["modal_substancia"].hide();
    },

    async desvincular_subs(substancia) {
      const { produto_id } = this.$route.params;
      // console.log(produto_id);
      // console.log(id_substancia);

      if (!produto_id) {
        this.fireAlert({
          title: "Nenhum produro foi selecionado!",
          tipo: "error",
          routeName: "aluno",
          timer: 5000,
        });

        this.$store.dispatch("MudarPreloader", false);
        return;
      }

      this.form_substancias.produto_id = produto_id;
      this.form_substancias.substancia_id = substancia.id;

      this.verif = true;
      await this.$store.dispatch(
        "produto/desvincular_substancia",
        this.form_substancias
      );
      // this.$store.state.produt       o.lista_substancias_produto = []
      this.$store.dispatch("produto/listar_substancias_produto", produto_id);
      this.verif = false;
      this.fireAlert(this.mensagem_alert);
      this.form_substancias.substancia_id = "";
      this.form_substancias.produto_id = "";

      this.$refs["modal_substancia"].hide();
    },

    async vinculoClasse() {
      const { produto_id } = this.$route.params;
      if (!produto_id) {
        this.fireAlert({
          title: "Nenhum produro foi selecionado!",
          tipo: "error",
          routeName: "aluno",
          timer: 5000,
        });
        this.$store.dispatch("MudarPreloader", false);
        return;
      }

      this.form_classe.produto_id = produto_id;
      this.verif = true;
      await this.$store.dispatch(
        "produto/vinculo_tipo_classe",
        this.form_classe
      );
      this.verif = false;
      this.fireAlert(this.mensagem_alert);
      this.form_classe.tipo_classe_id = "";
      this.form_classe.produto_id = "";
      this.$refs["modal_classes"].hide();
    },

    async desvincular_classe(classe) {
      const { produto_id } = this.$route.params;
      // console.log(produto_id);
      // console.log(id_substancia);

      if (!produto_id) {
        this.fireAlert({
          title: "Nenhum produto foi selecionado!",
          tipo: "error",
          routeName: "listaProduto",
          timer: 5000,
        });

        this.$store.dispatch("MudarPreloader", false);
        return;
      }

      this.form_classe.produto_id = produto_id;
      this.form_classe.tipo_classe_id = classe.id;

      // console.log( this.form_classe)

      this.verif = true;
      await this.$store.dispatch(
        "produto/desvincular_classe",
        this.form_classe
      );

      this.verif = false;
      this.fireAlert(this.mensagem_alert);

      this.$refs["modal_substancia"].hide();
      this.$store.dispatch("produto/listar_tipo_classe_produto", produto_id);
      this.form_classe.tipo_classe_id = "";
      this.form_classe.produto_id = "";
    },

    async setProdutoEstoque() {
      const { produto_id } = this.$route.params;
      if (!produto_id) {
        this.fireAlert({
          title: "Nenhum produro foi selecionado!",
          tipo: "error",
          routeName: "  ",
          timer: 5000,
        });
        return;
      }
      this.form_estoque.valor_unitario = this.form_estoque.valor_unitario.replace(/[^\d]/g, "");
      this.form_estoque.valor_fracionario = this.form_estoque.valor_fracionario.replace(/[^\d]/g, "");
      this.form_estoque.produto_id = produto_id;
      this.verif = true;
      await this.$store
        .dispatch("produto/setProdutoEstoque", this.form_estoque)
        .then(() => {
          let quanti = this.form.estoque_quantidade;
          this.form.estoque_quantidade =
            parseInt(quanti) + parseInt(this.form_estoque.estoque_quantidade);
          this.form.estoque_fracao =
            parseInt(this.form.estoque_quantidade) * parseInt(this.form.fracao);
          this.form_estoque.filial_fracao =
            this.lista_produtos_estoque.filial_fracao;

          this.preenxerCampos();
          this.verif = false;
          this.fireAlert(this.mensagem_alert);

          this.form_estoque.produto_id = "";
          this.form_estoque.data_recebimento = "";
          this.form_estoque.validade = "";
          this.form_estoque.lote = "";
          this.form_estoque.estoque_quantidade = "";
          this.form_estoque.valor = "";
          this.form_estoque.valor_unitario = "";
          this.form_estoque.valor_fracionario = "";


          this.$refs["modal_estoque"].hide();
        });
    },

    async deleteEstoque(item) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      this.verif = true;
      this.$store.dispatch("produto/deleteEstoque", item.id);
      this.preenxerCampos();
      this.verif = false;

      // await this.$store.dispatch(
      //   "produto/listar_produto_estoque_id",
      //   this.form.id
      // );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.fireAlert(this.mensagem_alert);
    },

    transferirEstoque(item) {
      console.log(item);
      this.form_transferencia.estoque_id = item.id;
      this.form_transferencia.data_validade = item.validade;
      this.form_transferencia.lote = item.lote;
      this.form_transferencia.produto_id = item.produto_id;
      this.form_estoque.estoque_quantidade = item.estoque_quantidade;
      this.form_transferencia.valor_unitario = item.valor_unitario;
      this.form_transferencia.valor = item.valor;
      this.form_transferencia.valor_fracionario = item.valor_fracionario
      this.$refs["modal_transferencia"].show();
    },
    async salvarTransferencia() {
      if (
        this.form_transferencia.estoque_quantidade >
        this.form_estoque.estoque_quantidade
      ) {
        alert("Esta transferindo mais do que há no estoque!");
        this.quantidade = 0;

      } else {
        this.verif = true;

        await this.$store
          .dispatch("produto/transferir_estoque",
            this.form_transferencia)
          .finally(() => {
            this.verif = false;

            if (this.mensagem_alert.tipo == "error") {
              this.alertError()
            } else {
              this.fireAlert(this.mensagem_alert)
              this.$refs["modal_transferencia"].hide();
            }

          });

        this.preenxerCampos();


      }

    },

    regularizarEstoque(item) {
      this.form_estoque = item
      this.form_regularizacao.estoque_fracao = item.estoque_fracao
      this.form_regularizacao.estoque_id = item.id,
        this.form_regularizacao.motivo = ""
      this.form_regularizacao.tipo_id = null
      this.$refs["modal_regularizacao"].show();
    },

    async salvarRegularizacao() {
      if (this.form_regularizacao.motivo == "") {
        Swal.fire({
          title: 'No se pudo guardar!',
          text: "Es necesário poner el motivo de la regularización de manera clara y objetiva!",
          footer: 'Verifique si todos los campos necesarios fueron llenados',
          confirmButtonText: "Terminar de llenar",
          confirmButtonColor: '#ff6060',
        });
        // alert("'No se pudo guardar!Es necesário poner el motivo de la regularización de manera clara y objetiva!")
      } else {

        this.verif = true;

        await this.$store
          .dispatch("produto/regularizar_estoque", this.form_regularizacao)
          .finally(() => {
            this.verif = false;

            if (this.mensagem_alert.tipo == "error") {
              console.log(this.mensagem_alert)
              this.alertError()
            } else {
              this.fireAlert(this.mensagem_alert)
              this.$refs["modal_regularizacao"].hide();
            }

          });

        this.preenxerCampos();


      }

    },


    alertError() {

      console.log("entrou no alert")

      var texto = "Verificar los campos: "
      var erros = this.mensagem_alert.message.errors

      if (erros.hasOwnProperty('data_transferencia')) {
        texto = texto + 'data_transferencia, '
      }
      if (erros.hasOwnProperty('filial_id')) {
        texto = texto + 'filial_id, '
      }
      if (erros.hasOwnProperty('estoque_id')) {
        texto = texto + 'estoque_id, '
      }
      if (erros.hasOwnProperty('tipo_id')) {
        texto = texto + 'tipo_id, '
      }
      if (erros.hasOwnProperty('estoque_quantidade')) {
        texto = texto + 'estoque_quantidade, '
      }
      if (erros.hasOwnProperty('estoque_fracao')) {
        texto = texto + 'estoque_fracao, '
      }
      if (erros.hasOwnProperty('cambios')) {
        texto = "¡No hubo cambios! La cantidad de fracción sigue siendo la misma."
      }

      Swal.fire({
        title: 'No se pudo guardar!',
        text: texto,
        footer: 'Verifique si todos los campos necesarios fueron llenados',
        confirmButtonText: "Terminar de llenar",
        confirmButtonColor: '#ff6060',
        // customClass: {
        //   icon: "center",
        // },
      });
    },

    editarEstoque(item) {

      this.form_estoque.produto_id = item.produto_id;
      this.form_estoque.data_recebimento = item.data_recebimento;
      this.form_estoque.validade = item.validade;
      this.form_estoque.descricao = item.descricao;
      this.form_estoque.lote = item.lote;
      this.form_estoque.estoque_quantidade = item.estoque_quantidade;
      this.form_estoque.estoque_fracao = item.estoque_fracao;
      this.form_estoque.sala_id = item.sala_id;
      this.form_estoque.fila = item.fila;
      this.form_estoque.coluna = item.coluna;
      this.form_estoque.valor = item.valor;
      this.form_estoque.valor_unitario = item.valor_unitario
      this.form_estoque.valor_fracionario = item.valor_fracionario
      console.log(item)
      if (this.form_estoque.valor != null) this.valor_total_estoque = this.fomatarGuarani(item.valor.toString());
      if (this.form_estoque.valor_unitario != null) this.form_estoque.valor_unitario = this.fomatarGuarani(item.valor_unitario.toString())
      if (this.form_estoque.valor_fracionario != null) this.valor_fracionario = this.fomatarGuarani(item.valor_fracionario.toString());


      this.form_estoque.filial_id = item.filial_id;
      this.form_estoque.estoque_id = item.id;
      console.log(this.form_estoque);
      this.$refs["modal_estoque"].show();

    },

    async salvarUpdateEstoque() {

      if (this.form_estoque.estoque_id) {
        console.log(this.form_estoque)
        this.verif = true;

        if (typeof this.form_estoque.valor_unitario === 'string') this.form_estoque.valor_unitario = this.form_estoque.valor_unitario.replace(/[^\d]/g, "");
        if (typeof this.form_estoque.valor_fracionario === 'string') this.form_estoque.valor_fracionario = this.form_estoque.valor_fracionario.replace(/[^\d]/g, "");

        await this.$store.dispatch("produto/atualizar", this.form_estoque);
        this.verif = false;
        this.fireAlert({
          ...this.mensagem_alert,
        });
        this.preenxerCampos();
      } else {
        console.log("Nenhum estoque id passado");
      }


      this.limpa_campos();
      this.$refs["modal_estoque"].hide();
    },

    async listar_requerimentos() {
      const empresa_id = getEmpresaId()
      console.log(empresa_id)

      if (empresa_id == 1) {
        this.empresa = "PJC"
      } else {
        this.empresa = "CDE"
      }

      if (this.lista_campos == "") {
        this.editarProduto = false;
      }

      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      const { produto_id } = this.$route.params;
      if (produto_id) {
        await this.$store.dispatch("produto/listar_produto", produto_id);
        var dados = {
          produto_id: produto_id,
          empresa_id: empresa_id
        }
        await this.$store.dispatch("produto/get_estoques_empresa", dados);
      }

      this.$store.dispatch("tipoClasse/listar_tipos_classe");
      this.$store.dispatch("substancia/listar_substancias");
      this.$store.dispatch("configEmpresa/listar_filial");
      this.$store.dispatch("configEmpresa/listar_sala");
      await this.$store.dispatch("produto/listar_tipos_produto");
      await this.$store
        .dispatch("laboratorio/listar_laboratorios")
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.preenxerCampos();
        });
    },

    async preenxerCampos() {
      console.log(this.lista_campos);

      if (this.lista_campos.id) {
        const { produto_id } = this.$route.params;
        if (this.lista_campos.id != produto_id) {
          this.fireAlert({
            title: "Nenhum produto foi selecionado!",
            tipo: "error",
            routeName: "produto",
            timer: 5000,
          });

          return;
        }
        await this.$store.dispatch("produto/listar_produto", produto_id);
        await this.$store.dispatch(
          "produto/listar_produto_estoque_id",
          produto_id
        );
        this.$store.dispatch("produto/listar_substancias_produto", produto_id);
        this.$store.dispatch("produto/listar_tipo_classe_produto", produto_id);

        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          tipo_produto_id: this.lista_campos.tipo_produto_id,
          codigo_barra: this.lista_campos.codigo_barra,
          descricao: this.lista_campos.descricao,
          status: this.lista_campos.status,
          user_in: this.lista_campos.user_in,
          user_up: this.lista_campos.user_up,
          filial_id: this.lista_campos.filial_id,
          fracao: this.lista_campos.fracao,
          laboratorio_id: this.lista_campos.laboratorio_id,
          estoque_quantidade: this.lista_campos.estoque_quantidade,
          estoque_fracao: this.lista_campos.estoque_fracao,
          quantidade: this.lista_campos.quantidade,
          filial_quantidade: this.lista_campos.filial_quantidade,
          filial_fracao: this.lista_campos.filial_fracao,
          tipo_entrada_id: this.lista_campos.tipo_entrada_id
        };
      }
    },

    limpa_campos() {
      this.form_estoque.estoque_id = null;
      this.form_estoque.produto_id = "";
      this.form_estoque.data_recebimento = "";
      this.form_estoque.validade = "";
      this.form_estoque.descricao = "";
      this.form_estoque.lote = "";
      this.form_estoque.estoque_quantidade = "";
      this.form_estoque.estoque_fracao = "";
      this.form_estoque.sala_id = "";
      this.form_estoque.fila = "";
      this.form_estoque.coluna = "";
      this.form_estoque.valor = "";
      this.form_estoque.filial_id = "";
      this.form_estoque.valor_unitario = "";
    },

    abrir_historico(item) {
      this.$refs["modal_historico"].show();
      this.dados_historico = JSON.parse(item.historico);
      for (let a in this.dados_historico) {
        const element = this.dados_historico[a].dados_anteriores.estoque_fracao;
        if (element != null) {
          console.log(element);
        }
      }
    },

    selecionarTipo(tipo) {
      if (tipo == "historico") {
        this.historico = true;
        this.saida = false;
      } else {
        this.historico = false;
        this.saida = true;
      }
    },

    fechar() {
      this.$refs["modal_historico"].hide();
    },
    formatarData(item) {
      const valid = item.item.validade
      const partesData = valid.split('-');
      const mes = partesData[1];
      const ano = partesData[0];
      const dataFormatada = `${mes}/${ano}`;
      return dataFormatada;
    },
    formatarDataReb(item) {
      const valid = item.item.data_recebimento
      const partesData = valid.split('-');
      const mes = partesData[1];
      const ano = partesData[0];
      const dia = partesData[2];
      const dataFormatada = `${dia}/${mes}/${ano}`;
      return dataFormatada;
    }


  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>